.kanbanFullView{
  position: fixed; /* Fixed to cover entire viewport */
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  height: 100vh;
  width: 100vw;
  z-index: 1001; /* Ensure high enough to be on top */
  color: white;
  font-size: 10vh;
  background-color: black;
  font-family: 'Noah Bold';
  padding: 10vw 20vh;
  @media screen and (max-width: 768px) {
    font-size: 8vw;
    padding: 5vw 5vh;
  }
}

.kanbanView {
//   text-align: center; /* Center text horizontally */
}


